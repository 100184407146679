import { DIALOG_DATA,DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { LabelService } from '../../services/label.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EnclaveService } from '../../services/enclave.service';
import { Observable } from 'rxjs';
import { elnResponse } from 'projects/enclave-studies/src/app/models/metadata';
interface DialogData {
  event?:string;
  view?:string;
  code?:number;
  response?:elnResponse;
  elnPro?:string[];
  payload:{
    vtIdNumber?:string;
    experimentId?:string;
    eln?:string[];
    datasetMetadataId:string;
  }
}
enum HttpStatus{
  AUTHORISE = 401,
  NOT_FOUND = 404,
  VTID = 405,
  MANY_REQ = 406,
  FAIL = 403
}
interface ErrorResponse<T> {
  [key:string]:T
}
interface SuccessfulResponse{
  [key:string]:any;
}
@Component({
  selector: 'app-endor-login',
  templateUrl: './endor-login.component.html',
  styleUrls: ['./endor-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndorLoginComponent implements OnInit {
  i18n = this.labelService.labels;
  errorCaseLogin:boolean=false;
  isLoading:boolean=false;
  fetchSuccess:boolean=false;
  loginView:boolean=false;
  errMessage:string=''
  successView:boolean=false;
  elnProperties!:string[]|undefined;
  endorData:SuccessfulResponse={};
  successMessage:string='';
  errorView:boolean=false;
  nonELN:boolean=false;
  showLoader:boolean=false;
  errorData:string=''
  apiCalls!:Observable<SuccessfulResponse>;
  NOT_FOUND:number=404;
  endorForm = new FormGroup({
    username: new FormControl('',{nonNullable: true}),
    password:new FormControl('', {nonNullable: true})
  })
  constructor(
  private readonly labelService: LabelService,
  private readonly configService:EnclaveService,
  @Inject(DIALOG_DATA) protected data: DialogData,
  public dialogRef: DialogRef<boolean|SuccessfulResponse>){}

  ngOnInit(): void {
    if(this.data && this.data.view ==='error' && this.data.code){
      this.errorView = true;
      this.loginView = false;
      this.isLoading = false;
      this.errorData = this.handleErrorCode(this.data.code,'tokenCheck');
    }else if(this.data && this.data.view === 'loader'){
      this.isLoading = true;
    }else if(this.data && this.data.view === 'fetchSuccess'){
      this.fetchSuccess = true;
      this.elnProperties = this.data.elnPro;
      if(this.data.response){
        this.showSuccessValue(this.data.response);
      }
      this.loginView = false;
    }else{
      this.loginView = true;
    }
    this.configService.fetchmetaData.subscribe(res => {
      if(res){
        this.dialogRef.close(true);
        this.configService.fetchmetaData.next(null);
      }
    })
  }
  onDecline(event:string) {
    (event === 'fail')?this.dialogRef.close(false):this.dialogRef.close(true);
  }
  onSend(){
    this.dialogRef.close(this.endorData);
  }
  onSubmit(event:Event) {
    event.preventDefault();
    this.errorCaseLogin = false;
    this.endorForm.get('username')?.setValidators(
      [Validators.required]);
    this.endorForm.get('username')?.markAllAsTouched();
    this.endorForm.get('username')?.updateValueAndValidity();
    this.endorForm.get('password')?.setValidators(
      [Validators.required]);
    this.endorForm.get('password')?.markAllAsTouched();
    this.endorForm.get('password')?.updateValueAndValidity();
    if (this.endorForm.valid) {
      this.showLoader = true;
      let payload = {
        username:this.endorForm.get('username')?.value,
        password:this.endorForm.get('password')?.value
      };
      if(this.data.event === 'eln'){
        this.elnProperties = this.data.payload?.eln;
        payload = {
          ...payload,
          ...this.data.payload
        }
        this.apiCalls = this.configService.validateEndor(payload);
      }else{
        this.apiCalls = this.configService.endorLogin(payload);
      }
      this.parseResponse(this.apiCalls);
    }
  }

  showSuccessValue(response:elnResponse){
    this.endorData = response;
    if(response && this.elnProperties?.length){
      if(this.elnProperties.every((key)=>response?.elnValues?.[key] === "")){
        this.successMessage = this.i18n.ENDOR_LOGIN.EMPTY_RESPONSE;
      }else{
        this.successMessage = this.i18n.ENDOR_LOGIN.FETCH_SUCCESS;
      }
    }
  }

  parseResponse(response:Observable<SuccessfulResponse>){
    response.subscribe({
      next: (resp: SuccessfulResponse) => {
        this.fetchSuccess=true;
        this.loginView = false;
        this.errorView = false;
        this.showLoader = false;
        this.showSuccessValue(resp?.['result']);
        if(this.data?.event === 'login'){
          this.nonELN = true;
        }
    },
    error: (_error:ErrorResponse<string|number>) => { 
      this.fetchSuccess=false;
      this.errorView = false;
      this.errorCaseLogin = false;
      this.showLoader = false;
      this.loginView = false;
      if(_error?.['status'] === HttpStatus.AUTHORISE || _error?.['status'] === HttpStatus.FAIL){
        this.errorCaseLogin = true;
        this.loginView = true;
      }else{
        this.errorView = true;
        this.loginView = false;
      }
      this.errorData = this.handleErrorCode(_error?.['status'],'loginCode');
    }
  })  
  }

  handleErrorCode(code:number|string,event:string):string{
    let errMessage = '';
    if(event === 'loginCode' && Number(code) === HttpStatus.AUTHORISE){
      errMessage = this.i18n.ENDOR_LOGIN.ERROR_MSG_401;
    }else if(Number(code) === HttpStatus.FAIL){
      errMessage = this.i18n.ENDOR_LOGIN.ERROR_MSG_403;
    }else if(Number(code) === HttpStatus.MANY_REQ){
      errMessage = this.i18n.ENDOR_LOGIN.ERROR_MSG_406;
    }else if(Number(code) === HttpStatus.NOT_FOUND){
      errMessage = this.i18n.ENDOR_LOGIN.ERROR_MSG_404;
    }else if(Number(code) === HttpStatus.VTID){
      errMessage = this.i18n.ENDOR_LOGIN.ERROR_MSG_405;
    }else{
      errMessage = this.i18n.ENDOR_LOGIN.TECHNICAL_ERROR;
    }
    return errMessage;
  }
}
