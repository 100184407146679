import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { environment as env} from 'src/environments/environment';
import { Enclave, EnclaveResponse, EndorPayload } from '../models/enclave';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnclaveService {
  enclaveDetails!: Enclave;
  readonly schemaSelected = new BehaviorSubject<string>("");
  readonly schemaUploaded = new BehaviorSubject<boolean>(false);
  readonly uploadedfileName = new BehaviorSubject<string>("");
  readonly fileUploadInProgress = new BehaviorSubject<number>(0);
  readonly fetchmetaData = new BehaviorSubject<number|null>(0);
  constructor(
    private readonly configService: ConfigService,
    private http: HttpClient
  ) { }

  getEnclaveDetails(){
    const url = this.configService.config['domain'] + env.enclaveDetails
    //const url = 'http://localhost:3000/dp-enclave-management-service/api/v1/enclave'
    return this.http.get<EnclaveResponse>(url);
  }

  saveEnclaveDetails(id: string, payload: Enclave){
    const url = this.configService.config['domain'] + env.enclavePutAPI + '/' + id
    return this.http.put<EnclaveResponse>(url, payload);
  }

  isConfigured(){
    return this.enclaveDetails.enclaveStatus.toLowerCase() 
                    === env.ENC_CONF.STATUS_CONFIGURED.toLowerCase() 
  }

  updateUser() {
    const url = this.configService.config['domain'] + env.updateUser 
    return this.http.post(url, {});
  }

  validateEndor(payload:EndorPayload) {
    const url = this.configService.config['domain'] + env.validateEndorToken;
    return this.http.post(url, payload);
  }

  endorLogin(payload:EndorPayload) {
    const url = this.configService.config['domain'] + env.endorLogin;
    return this.http.post<any>(url, payload);
  }
  uploadCustomizedMetadataSchema(formData : FormData, headers: any) {
    const url = this.configService.config['domain'] + env.fileUploadForMetadata
    return this.http.post(url, formData, { headers });
  }
}
