import { ControlBase } from './control-base';

export class Metadata {
    name: string;
    controls: ControlBase<string>[];
    isFormInValid: boolean;
    constructor(name: string, controls: ControlBase<string>[], isFormInValid: boolean = false) {
        this.name = name;
        this.controls = controls;
        this.isFormInValid = isFormInValid;
    }
}

export interface elnPayload{
    view?:string;
    vtIdNumber : string;
    experimentId: string;
    eln:string[];
    datasetMetadataId?:string;
}
export interface elnResponse{
    elnValues:elnFields;
    experimentGuid?:string;
}
export interface elnFields {
    [key:string]:string
}