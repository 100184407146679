<owc-header #headerElement class="app-header">
    <owc-header-row #headerRowElement separator elevated [variant]="'secondary'" class="primary-slot">
        <div>
            <owc-typography [device]="'desktop'" [variant]="'body2'">
                <span class="title title-color">
                    {{i18n.APP_TITLE }}
                </span>
            </owc-typography>
            <span class="separator"></span>
            <owc-typography [device]="'desktop'" [variant]="'body2'">
                <span class="title-color">
                    {{ i18n.APP_TITLE_2 }}
                </span>
            </owc-typography>
        </div>
        <div class="right-section">
            <owc-typography [variant]="'badge'">
                {{ sessionInfo.profile.displayName }}
            </owc-typography>
            <owc-icon-button id="profileMenuPopOver" family="outlined" icon="user"></owc-icon-button>
            <owc-tooltip #tooltipElementProfile [anchor]="'profileMenuPopOver'">
                <span>{{ i18n.HEADER.PROFILE.TOOLTIP }}</span>
            </owc-tooltip>
            <owc-icon-button id="helpMenu" class="icon-button mr10" icon="circle_help"
                [disabled]="false"></owc-icon-button>
            <owc-tooltip #tooltipElementHelp [anchor]="'helpMenu'">
                <span>{{ i18n.HEADER.HELP_TOOLTIP }}</span>
            </owc-tooltip>
            <owc-logo #logoElement [size]="'alternate'">
            </owc-logo>
        </div>
    </owc-header-row>


    <owc-popover #popoverElement [anchor]="'profileMenuPopOver'" [trigger]="'click'" [offset]="[30,0]"
        (visibleChange)="visibleChangeHandler($event)">
        <div class="popover-content">
            <div class="popover-list">

                <owc-list class="popover-list">
                    <owc-list-item>
                        <owc-typography #typographyElement [element]="'sub'" [variant]="'subtitle1'">
                            {{ i18n.HEADER.PROFILE.TITLE }}
                        </owc-typography>
                    </owc-list-item>
                    <owc-list-item (click)="endorLogin()">
                        <owc-typography #typographyElement [element]="'sub'" [variant]="'subtitle1'">
                            {{ i18n.HEADER.PROFILE.ENDOR_LOGIN }}
                        </owc-typography>
                    </owc-list-item>
                </owc-list>
                <owc-divider class="popover-list"></owc-divider>
                <owc-button variant="secondary" (click)="logout()" class="logout">
                    {{ i18n.HEADER.PROFILE.LOGOUT_BTN}}
                </owc-button>
            </div>
        </div>
    </owc-popover>
</owc-header>