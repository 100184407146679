<div class="confirm-popup padd-16">
  <owc-modal-dialog
    #owcConfigModelDialog
    disable-backdrop-click
    [visible]="true"
  >
    <div class="modal-header">
      <owc-typography class="header-title" [variant]="'title6'"
        ><span *ngIf="loginView">{{
          i18n.ENDOR_LOGIN.HEADER
        }}</span></owc-typography
      >
      <owc-icon-button
        *ngIf="!isLoading && !fetchSuccess && !errorView"
        (click)="onDecline('fail')"
        [icon]="'clear'"
      ></owc-icon-button>
    </div>
    <div class="modal-content" *ngIf="loginView">
      <owc-typography class="heading">
        {{ i18n.ENDOR_LOGIN.SUB_DESC }}
      </owc-typography>
      <div class="error-case" *ngIf="errorCaseLogin">
        <owc-typography class="error-text">
          {{ errorData }}
        </owc-typography>
      </div>
    </div>

    <span *ngIf="loginView">
      <div class="modal-content" class="padd-16" [formGroup]="endorForm">
        <owc-grid #gridElement [columns]="1" [spacing]="24">
          <owc-grid-item>
            <owc-input
              #inputElement
              [cols]="30"
              [rows]="3"
              class="owc-input-width input-margin-top"
              [variant]="'outlined'"
              label="Username"
              formControlName="username"
              [appFormControlValidationMsg]="{
                default: '',
                required: i18n.STUDY.STEP_1.ERRORMSG.REQUIRED_FIELD
              }"
            >
              <owc-icon slot="prefix" name="user" family="outlined"></owc-icon>
            </owc-input>
          </owc-grid-item>
          <owc-grid-item>
            <owc-input
              #inputElement
              [cols]="30"
              [rows]="3"
              class="owc-input-width input-margin-top"
              [variant]="'outlined'"
              label="Password"
              type="password"
              formControlName="password"
              autocomplete="off"
              [appFormControlValidationMsg]="{
                default: '',
                required: i18n.STUDY.STEP_1.ERRORMSG.REQUIRED_FIELD
              }"
            >
              <owc-icon
                slot="prefix"
                name="lock_on"
                family="outlined"
              ></owc-icon> </owc-input
          ></owc-grid-item>
        </owc-grid>
      </div>
      <div class="modal-footer">
        <div></div>
        <div class="d-flex">
        <owc-button (click)="onSubmit($event)" class="btn-cls" [disabled]="showLoader">
          {{ i18n.ENDOR_LOGIN.LOGIN_BTN }}</owc-button
        >
        <span class="loadSpinner" *ngIf="showLoader">
          <owc-progress-spinner [size]="'small'" [value]="50" [variant]="'secondary'" [value]="50" />
      </span>
        </div>
      </div></span
    >
    
    <div class="success-content" *ngIf="isLoading">
      <owc-progress-spinner
        #progressSpinnerElement
        [size]="'xxLarge'"
        [value]="50"
      />
      <owc-typography class="close-btn">
        {{ i18n.ENDOR_LOGIN.FETCH_DATA }}
      </owc-typography>
    </div>
    <div class="success-content" *ngIf="fetchSuccess">
      <img alt="enclave-saved" src="/assets/images/save_exit_tick.svg" />
      <owc-typography class="close-btn" *ngIf="!nonELN">
        {{ successMessage }}
      </owc-typography>
      <owc-typography class="close-btn" *ngIf="nonELN">
        {{ i18n.ENDOR_LOGIN.SUCCESS_DESC }}
      </owc-typography>
      <owc-button (click)="onSend()" class="close-btn">
        {{ i18n.IMAGE_POPUP.CLOSE_BTN }}</owc-button
      >
    </div>
    <div class="success-content" *ngIf="errorView">
      <owc-icon
        #iconElement
        class="font-size close-btn"
        [alt]="''"
        [name]="'alarm'"
        [status]="'warning'"
      >
      </owc-icon>
      <owc-typography class="error-text">
        {{ errorData }}
      </owc-typography>
      <owc-button (click)="onDecline('success')" class="close-btn">
        {{ i18n.IMAGE_POPUP.CLOSE_BTN }}</owc-button
      >
    </div>
    <div class="success-content" *ngIf="successView">
      <owc-typography class="header-title" [variant]="'title6'">
        {{ i18n.ENDOR_LOGIN.SUCCESS_TITLE }}
      </owc-typography>
      <owc-typography>
        {{ i18n.ENDOR_LOGIN.SUCCESS_DESC }}
      </owc-typography>
      <owc-button (click)="onDecline('success')" class="close-btn">
        {{ i18n.IMAGE_POPUP.CLOSE_BTN }}</owc-button
      >
    </div>
  </owc-modal-dialog>
</div>
